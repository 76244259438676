/* General homepage container */
html, body, .topbar, .folder-link, .image-description, .work-date {
    font-family: Arial, sans-serif;
    color: black;
    text-transform: uppercase;
}

/* Homepage container settings */
.homepage {
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 100%;
    overflow-y: auto;
}

/* Unified Topbar styling */
.topbar {
    display: flex;
    justify-content: space-between; /* Space out the elements in the topbar */
    align-items: center; /* Vertically center content */
    padding: 10px 20px; /* Adjusted padding for consistency */
    background-color: transparent;
    z-index: 10;
    height: 60px; /* Fixed height */
    top: 0;
    width: 100%; /* Ensure the topbar stretches across the page */
    border-bottom: none;
    border-top: none;
    border-left: none;
    border-right: none;
    font-weight: bold;
}

/* Logo styling (using flex for centering) */
.topbar-logo {
    width: 200px; /* Maximum width constraint */
    height: auto; /* Take up 80% of the topbar height */
    object-fit: contain; /* Maintain aspect ratio */
    flex-grow: 0;
    margin: 0 auto; /* Centers the logo in its container */
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .topbar-logo {
        width: 120px; /* Smaller max width for mobile */
        height: auto; /* Slightly adjusted height for mobile */
    }
}

/* Info button and other topbar button styling */
.topbar-button {
    background-color: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
    margin: 0 10px;
    padding: 10px 20px;
 

}

.topbar-button:hover {
    background-color: transparent
}

.info-button {
    margin-left: auto; /* Pushes info button to the far right */
}

/* Responsive adjustments */
@media (max-width: 600px) {


    /* Ensure topbar buttons and layout adjust for small screens */
    .topbar-button {
        font-size: 14px;
        padding: 8px 16px;
    }

    .topbar {
        padding: 10px; /* Adjust padding for smaller screens */
    }
}


/* Card container for each item */
.card-container {
    position: relative;
    overflow: hidden;
    border-radius: 0;
    box-shadow: none;
    grid-gap: 0; /* Remove gap between grid items */
    gap: 0; 
    
}

/* Card image styling */
.card-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 3/2;
    border-radius: 0;
    margin: 0;
}

/* Overlay that appears when hovering over the card */
.card-overlay {
    text-transform: uppercase;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.847);
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 20px;
    font-weight: normal;
    pointer-events: none;
    font-weight: bold;
}

.card-container:hover .card-overlay {
    opacity: 1;
}



/* Container for the grid of cards */
.grid-container {
    padding: 10px;
    flex-grow: 1;
    margin: 0 auto;
    max-width: 1200px;
    grid-gap: 0 !important; /* Remove gap between grid items */
    gap: 0; 
}
