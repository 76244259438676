/* General homepage container */
.homepage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;           /* Full viewport height */
  background-color: white;
  margin: 0;               /* Remove any margin */
  padding: 0;              /* Ensure no extra padding */
  width: 100%;             /* Full width */
  overflow: hidden;        /* Prevent content overflow */
}

/* Content container for logo */
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  width: 100vw;  /* Full viewport width */
  overflow: hidden; /* Prevent any overflow */
  background-color: white;
}

/* Logo Image */
.logo-image {
  width: 100%;       /* Fill the container width */
  height: 100%;      /* Fill the container height */
  object-fit: cover; /* Ensure it covers the container, cropping if necessary */
  cursor: pointer;
}

/* Mobile Specific Adjustments */
@media (max-width: 600px) {
  .content {
    height: 100vh; /* Full height of the viewport */
    width: 100vw;  /* Full width of the viewport */
  }

  .logo-image {
    width: 100vw;    /* Ensure it fills the width */
    height: 100vh;   /* Ensure it fills the height */
    object-fit: cover; /* Allow cropping to ensure full coverage */
  }
}
