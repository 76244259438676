html, body, .topbar, .folder-link, .image-description, .work-date {
  font-family: Arial;
  color: black;
  text-transform: uppercase;
}

/* Unified approach for image description responsiveness */
.image-description {
  width: 100%;
  max-width: 1100px;
  margin-top: 20px; /* Ensures the description starts below the carousel image */
  padding: 0 15px;
  text-align: left;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 60px;
  margin-right: auto;
  margin-bottom: 100px;
  word-wrap: break-word;
  word-break: break-word;
}

@media (min-width: 1100px) {
  .image-description {
    padding-left: 150px;
    padding-right: 15px;
  }
}

/* Adjust for mobile and smaller screens */
@media (max-width: 1100px) {
  .image-description {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    box-sizing: border-box;
  }

  .image-description h2 {
    font-size: 2rem;
  }

  .material-info, .work-date {
    font-size: 1.1rem;
  }
}

.material-info{
  color: gray;
}

@media (max-width: 768px) {
  .image-description {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .image-description h2 {
    font-size: 1.8rem;
  }

  .material-info, .work-date {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .image-description {
    padding: 5px;
  }

  .image-description h2 {
    font-size: 1.5rem;
  }

  .material-info, .work-date {
    font-size: 0.9rem;
  }
}

/* Adjust carousel container for responsiveness */
.carousel-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  transition: transform 0.3s ease;
  width: 100%;
  max-width: 1100px; /* Limit width to avoid overflowing */
}
.center-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}

.carousel-image-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.carousel-image {
  max-width: 100%;
  height: auto;
  object-fit: scale-down;
  margin: 0 auto;
}

/* Thumbnails container */
.thumbnails-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
  width: 100%;
  max-width: 1100px;
}

@media (min-width: 1100px) {
  .folder-navigation-buttons {
    display: none !important;
  }

  .left-section .folder-navigation-buttons {
    display: flex !important;
    position: static;
  }

  .left-section {
    display: flex !important;
    position: relative;
  }
}

@media (max-width: 1100px) {
  .left-section .folder-navigation-buttons {
    display: none !important;
    position: absolute;
    top: -9999px;
  }

  .left-section {
    display: none !important;
    position: absolute;
    top: -9999px;
  }

  .folder-navigation-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    box-sizing: border-box;
  }
}
.folder-navigation-buttons{
  margin-top: 20px;
}

.prev-work {
  margin-right: auto;
}

.next-work {
  margin-left: auto;
}

.arrow-left, .arrow-right {
  z-index: 2;
}

/* Adjust logo positioning for responsiveness */
.topbar-logo {
  position: absolute;
  left: 50%;                      /* Center the logo horizontally */
  transform: translateX(-50%);    /* Center-align relative to its new left position */
  max-height: 50px;               /* Restrict logo height */
  object-fit: contain;
  margin: 0;
  padding: 0;
}

/* Adjust for smaller screen sizes */
@media (max-width: 1200px) {
  /* Shift the logo to the left */
  .topbar-logo {
    left: 10% !important;         /* Shift logo to the left */
    transform: translateX(0);     /* Remove centering */
    max-height: 40px;             /* Smaller logo size */
  }

  /* Prevent the left-section from being visible on small screens */
  .left-section {
    display: none !important;
    position: absolute;
    top: -9999px;
  }

  .button-group {
    margin-left: auto;            /* Align the buttons to the far-right */
    display: flex;
    gap: 10px;
  }
}

/* Further adjustments for even smaller screens */
@media (max-width: 768px) {
  .topbar-logo {
    left: 10% !important;         /* Keep logo left-aligned */
    transform: translateX(0);     /* No centering */
    max-height: 35px;             /* Slightly smaller logo */
  }

  .topbar {
    justify-content: space-between;  /* Ensure topbar content is spaced correctly */
    padding: 0 10px;                 /* Reduce padding for smaller screens */
  }

  .button-group {
    gap: 5px;                      /* Reduce button spacing */
  }
}

/* For even smaller screens (like mobile phones) */
@media (max-width: 480px) {
  .topbar-logo {
    left: 10%;                      /* Maintain left position */
    max-height: 30px;               /* Reduce logo size further */
  }
}

/* For improved topbar styling and to avoid excessive height: */
.topbar {
  padding: 0 20px;  /* Added some padding to avoid too much empty space */
  height: 50px;     /* Reduced height */
  box-sizing: border-box; /* Ensure padding doesn't increase height */
}

.topbar-button {
  text-decoration: none;
  color: #000;
  font-size: 16px;
  padding: 5px 10px;
  transition: color 0.3s ease, background-color 0.3s ease;
  border: none;
  background: transparent;
}

.topbar-button:hover {
  color: #555;
  background-color: #f0f0f0;     /* Subtle hover effect */
}

.folder-view {
  display: flex;
  margin-top: 20px;
  padding: 0;
  align-items: flex-start;
  gap: 0px;
  width: 100%;
}

.left-section {
  flex: 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  padding-left: 20px;
  z-index: 1;
  margin-left: 20px;
  max-width: 200px;
}

.folder-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.folder-link {
  font-size: 0.8rem;
  text-decoration: none;
  color: #333; /* Default color */
  transition: color 0.3s ease;
  white-space: normal;
  overflow: visible;
  word-wrap: break-word;
}

.folder-link:hover {
  color: #007bff;
}

/* Highlight the active folder with a lighter gray */
.folder-link.active {
  color: #aaa; /* Lighter gray for the active folder */
}


.carousel-image {
  max-width: calc(100% - 40px);
  width: auto;
  height: auto;
  max-height: 70vh;
  object-fit: scale-down;
  margin: 0 auto;
}

.arrow {
  z-index: 10; /* Ensure above other elements */
  position: absolute;
}

.arrow-left {
  left: 5px;
  border-style: solid;
  border-color: transparent rgba(255, 255, 255, 0.8) transparent transparent;
}

.arrow-right {
  right: 5px;
  border-style: solid;
  border-color: transparent transparent transparent rgba(255, 255, 255, 0.8);
}

.arrow:hover {
  opacity: 1;
}

.thumbnails-container {
  grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));
}

.thumbnail {
  width: 80px;
  height: auto;
  cursor: pointer;
  border: 0.1px solid transparent;
  transition: border 0.3s;
}

.thumbnail.selected {
  border-color: #007bff;
}

@media (max-width: 768px) {
  .thumbnail {
    width: 80px;
  }
}

@media (max-width: 480px) {
  .thumbnail {
    width: 100%;
    max-width: 70px;
  }
}

.large-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.large-image {
  width: auto;
  height: auto;
  max-width: 100%;
  object-fit: contain;
  margin: 10px 0;
  cursor: pointer;
}

.center-section.full-width {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carousel-content .click-to-enlarge {
  font-size: calc(13px + 0.1vw);
  text-align: right;
  margin-top: 2px;
  margin-right: 10px;
  color: gray;
  cursor: default;
  display: block;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  cursor: pointer;
  z-index: 10;
}

.arrow-left {
  left: 20px;
  border-right: 8px solid white;
}

.arrow-right {
  right: 20px;
  border-left: 8px solid white;
}

.arrow:hover {
  opacity: 0.7;
}

.click-to-enlarge {
  text-align: center;
  color: white;
  font-size: 14px;
  margin-top: 10px;
  opacity: 0.8;
  cursor: pointer;
}

.click-to-enlarge:hover {
  opacity: 1;
}
