/* General Styling */
html, body, .topbar, .folder-link, .image-description, .work-date, .material-info {
  font-family: Arial, sans-serif;
  color: black;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}

/* Info Page Styling */
.info-page {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 100%; /* Allow for full height of content */
  overflow-y: auto; /* Enable vertical scrolling */
}

/* Topbar Styling */
.topbar {
  display: flex;
  justify-content: space-between; /* Align items to both sides */
  align-items: center;
  padding: 10px 20px; /* Adjust padding for consistent layout */
  background-color: #f8f8f8; /* Light background similar to gallery */
  border-bottom: 1px solid #ccc; /* Border for separation */
  position: relative;
  z-index: 10; /* Ensure the topbar stays above the content */
}

.topbar-logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 50px; /* Adjusted size */
  height: auto;
}

.topbar-button {
  background-color: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
  margin: 0 10px; /* Space between buttons */
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.topbar-button:hover {
  background-color: #e0e0e0;
}

/* Info Content Styling */
.info-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  background-color: white;
  margin-top: 80px; /* Ensure there's space for the topbar */
}

.info-text p {
  font-size: 16px;
  margin: 5px 0;
}

.info-text p strong {
  font-size: 18px;
  margin-bottom: 10px;
}

/* Indentation for Education and Employment */
.info-text p:nth-of-type(2), 
.info-text p:nth-of-type(3),
.employment-list li {
  margin-left: 100px;
}

.employment-list {
  list-style-type: none;
  padding-left: 0;
}

.employment-list li {
  font-size: 14px;
  margin-bottom: 5px;
}

.employment-list a {
  text-decoration: none;
  color: blue;
}

.employment-list a:hover {
  text-decoration: underline;
}

/* Space Between Sections */
.space-between {
  margin-top: 20px;
}

/* Email Styling */
.email-link {
  color: black;
  text-decoration: none;
}

.email-link:hover {
  text-decoration: underline;
}

/* Footer Styling */
.footer {
  font-size: 14px;
  text-align: center;
  padding: 10px;
  background-color: #f8f8f8;
  color: black;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #ccc;
}

.credits {
  font-size: 14px;
  color: #ef8ed2 !important;
  margin-top: 30px;
  text-align: left;
  padding-top: 30px;
}

.employment-spacer {
  list-style: none;
  margin: 40px 0; /* Adjust the space size as needed */
}


/* Responsive Adjustments */
@media (max-width: 600px) {
  .info-page {
    height: auto; /* Allow for automatic height on small screens */
  }

  .grid-container {
    padding: 10px;
    flex-grow: 1;
    margin-top: 0;
  }

  .topbar-logo {
    width: 40px; /* Smaller logo on mobile */
  }

  /* Adjust text size and spacing on small screens */
  .info-text p {
    font-size: 14px; /* Slightly smaller text */
  }

  .info-content {
    padding: 15px; /* Reduced padding for mobile */
  }
}

@media (min-width: 768px) {
  /* Increase font size for larger screens */
  .info-text p {
    font-size: 18px;
  }

  .employment-list li {
    font-size: 16px;
  }

  .employment-list {
    margin-left: 130px; /* Increased indentation */
  }

  /* Topbar adjustments for larger screens */
  .topbar {
    justify-content: space-between;
  }
}